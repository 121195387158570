html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  background-color: #000;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 3.6rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  letter-spacing: -.08rem;
  font-size: 2.2rem;
}

h4 {
  letter-spacing: -.05rem;
  font-size: 1.8rem;
}

h5 {
  letter-spacing: 0;
  font-size: 1.6rem;
}

h6 {
  letter-spacing: 0;
  font-size: 1.4rem;
}

/*# sourceMappingURL=index.f22c69de.css.map */
